import type { ElementType, ReactNode } from "react";
import { createContext, useContext, useState } from "react";
import { inputStyles } from "@capterra/vendor-ui-components-textinput";
import {
  Listbox,
  ListboxOption,
  ListboxOptions,
  ListboxButton,
  type ListboxProps,
  type ListboxOptionProps,
} from "@headlessui/react";

import {
  Inline,
  IconNew as Icon,
  ScrollArea,
  Checkbox,
  Text,
  Popover,
} from "~/components/vendorUI";
import { cva, css, cx } from "ui/css";

const overFlowTextCSS = cva({
  base: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  variants: {
    placeholder: {
      true: {
        color: "text.secondary",
      },
    },
  },
});

const listOptionCSS = css({
  "@layer base": { all: "unset" },
  padding: "16px",
  textSize: "14px",
  position: "relative",
  userSelect: "none",
  cursor: "pointer",

  "&[data-headlessui-state='disabled']": {
    pointerEvents: "none",
    color: "text.secondary",
    cursor: "default",
  },
  "&[data-headlessui-state~='active']": {
    backgroundColor: "bg.formControlOptionActive",
    color: "inherit",
  },
  "&[data-headlessui-state='active'], &[data-headlessui-state='active selected']":
    {
      backgroundColor: "bg.formControlOptionActive",
      color: "inherit",
    },
  "&[data-headlessui-state='selected']": {
    backgroundColor: "bg.formControlOptionSelected",
    color: "text.inverted",
  },
});

const listOptionsCSS = css({
  position: "absolute",
  width: "auto",
  minWidth: "var(--radix-popover-trigger-width, 100%)",
  background: "white",
  boxShadow: "2",
  borderRadius: "2px",
  overflow: "hidden",
  zIndex: "dropdown",
  "&[aria-multiselectable='true'] [data-multiselect-option] label": {
    pointerEvents: "none",
  },

  "&[aria-multiselectable='true'] [data-multiselect-option][data-headlessui-state='selected']":
    {
      background: "{colors.transparent}",
      color: "text",
    },
  "&[aria-multiselectable='true'] [data-multiselect-option][data-headlessui-state='active selected']":
    {
      background: "bg.formControlOptionActive",
      color: "text",
    },
});

export type SelectType<ValueType> = {
  children: ReactNode | ReactNode[];
  placeholder?: ReactNode;
  renderValue: (item: ValueType) => ReactNode;
  maxHeight?: string | number;
} & ListboxProps<ElementType, ValueType, ValueType>;

type SelectContextType = { multiple: boolean };

const SelectContext = createContext<SelectContextType>({ multiple: false });

const DEFAULT_DROPDOWN_HEIGHT = 260;

export const Select = <ValueType,>({
  multiple = false,
  children,
  renderValue,
  placeholder,
  maxHeight = DEFAULT_DROPDOWN_HEIGHT,
  ...rest
}: SelectType<ValueType>) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Listbox
      as="div"
      multiple={multiple}
      style={{ position: "relative" }}
      {...rest}
    >
      <Popover open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
        <Popover.Trigger asChild>
          <ListboxButton
            className={inputStyles()}
            data-testid="vp-select-trigger"
          >
            {({ value }) => {
              const hasValue = multiple ? value.length > 0 : Boolean(value);

              return (
                <Inline align="center" distribute="between">
                  <span className={overFlowTextCSS({ placeholder: !hasValue })}>
                    {hasValue ? renderValue(value) : placeholder}
                  </span>
                  <Icon
                    name="ExpandDown"
                    size="24px"
                    color="black"
                    className={css({ translate: "0 1px" })}
                  />
                </Inline>
              );
            }}
          </ListboxButton>
        </Popover.Trigger>

        <Popover.Content sideOffset={0}>
          <ListboxOptions className={listOptionsCSS}>
            <ScrollArea maxHeight={maxHeight}>
              <SelectContext.Provider value={{ multiple }}>
                {children}
              </SelectContext.Provider>
            </ScrollArea>
          </ListboxOptions>
        </Popover.Content>
      </Popover>
    </Listbox>
  );
};

export type SelectOptionType<T> = {
  renderLabel: (item: T) => ReactNode;
} & ListboxOptionProps<ElementType, T>;

function SelectOption<T>({
  value,
  renderLabel,
  disabled = false,
  className,
  ...rest
}: SelectOptionType<T>) {
  const { multiple } = useContext(SelectContext);

  const labelJsx =
    typeof renderLabel(value) === "string" ? (
      <span
        className={overFlowTextCSS()}
        title={renderLabel(value)?.toString()}
      >
        {renderLabel(value)}
      </span>
    ) : (
      renderLabel(value)
    );

  if (!multiple)
    return (
      <Popover.Close asChild>
        <ListboxOption
          data-multiselect-option
          className={cx(listOptionCSS, className)}
          value={value}
          disabled={disabled}
          {...rest}
        >
          {labelJsx}
        </ListboxOption>
      </Popover.Close>
    );

  return (
    <ListboxOption
      data-multiselect-option
      className={cx(listOptionCSS, className)}
      value={value}
      disabled={disabled}
      {...rest}
    >
      {({ selected }: { selected: boolean }) => (
        <Inline align="center" gap="8px">
          <Checkbox readOnly checked={selected} />
          {labelJsx}
        </Inline>
      )}
    </ListboxOption>
  );
}

const groupLabelCSS = css({
  paddingInline: "16px",
  paddingBlock: "8px",
});

type GroupLabelProps = React.ComponentProps<typeof Text>;

Select.Option = SelectOption;

Select.Group = ({ className, ...props }: { className?: string }) => (
  <div
    className={cx(css({ paddingBlockStart: "16px" }), className)}
    {...props}
  />
);

Select.GroupLabel = (props: GroupLabelProps) => (
  <Text
    className={groupLabelCSS}
    color="brand"
    size="14px"
    weight="medium"
    {...props}
  />
);
